import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { SEO, SmallPrintStyle } from 'components';

class CookiePolicy extends PureComponent {
  componentDidMount() {
    if (window.___INITIAL_RENDER) {
      setTimeout(() => {
        window.fadeOutSplashScreen();
      }, 750);
    }
  }

  render() {
    return (
      <Wrapper>
        <SEO
          pageSpecificTitle="Cookie Policy"
          pageSpecificDescription="Our Cookie Policy"
          meta={[
            {
              name: 'robots',
              content: 'noindex'
            }
          ]}
        />
        <SmallPrintStyle />
        <h1>Cookie Policy for Get Broadcasting</h1>
        <p>This is the Cookie Policy for Get Broadcasting, accessible from getbroadcasting.com</p>
        <b>What Are Cookies</b>
        <p>
          As is common practice with almost all professional websites this site uses cookies, which
          are tiny files that are downloaded to your computer, to improve your experience. This page
          describes what information they gather, how we use it and why we sometimes need to store
          these cookies. We will also share how you can prevent these cookies from being stored
          however this may downgrade or ‘break’ certain elements of the sites functionality.
        </p>
        <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>
        <b>How We Use Cookies</b>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately in most cases there
          are no industry standard options for disabling cookies without completely disabling the
          functionality and features they add to this site. It is recommended that you leave on all
          cookies if you are not sure whether you need them or not in case they are used to provide
          a service that you use.
        </p>
        <b>Disabling Cookies</b>
        <p>
          You can prevent the setting of cookies by adjusting the settings on your browser (see your
          browser Help for how to do this). Be aware that disabling cookies will affect the
          functionality of this and many other websites that you visit. Disabling cookies will
          usually result in also disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies.
        </p>
        <ul>
          <b>The Cookies We Set</b>
          <li>
            <h5>Email Newsletter Related Cookies</h5>
            <p>
              This site offers newsletter or email subscription services and cookies may be used to
              remember if you are already registered and whether to show certain notifications which
              might only be valid to subscribed/unsubscribed users.
            </p>
          </li>
          <li>
            <h5>Survey Related Cookies</h5>
            <p>
              From time to time we offer user surveys and questionnaires to provide you with
              interesting insights, helpful tools, or to understand our user base more accurately.
              These surveys may use cookies to remember who has already taken part in a survey or to
              provide you with accurate results after you change pages.
            </p>
          </li>
          <li>
            <h5>Form Related Cookies</h5>
            <p>
              When you submit data to through a form such as those found on contact pages or comment
              forms cookies may be set to remember your user details for future correspondence.
            </p>
          </li>
          <li>
            <h5>Site Preferences Cookies</h5>
            <p>
              In order to provide you with a great experience on this site we provide the
              functionality to set your preferences for how this site runs when you use it. In order
              to remember your preferences we need to set cookies so that this information can be
              called whenever you interact with a page is affected by your preferences.
            </p>
          </li>
        </ul>
        <br />
        <b>Third Party Cookies</b>
        <p>
          In some special cases we also use cookies provided by trusted third parties. The following
          section details which third party cookies you might encounter through this site.
        </p>
        <ul>
          <li>
            <p>
              This site uses Google Analytics which is one of the most widespread and trusted
              analytics solution on the web for helping us to understand how you use the site and
              ways that we can improve your experience. These cookies may track things such as how
              long you spend on the site and the pages that you visit so we can continue to produce
              engaging content.
            </p>
            <p>
              For more information on Google Analytics cookies, see the official Google Analytics
              page.
            </p>
          </li>
          <li>
            <p>
              Third party analytics are used to track and measure usage of this site so that we can
              continue to produce engaging content. These cookies may track things such as how long
              you spend on the site or pages you visit which helps us to understand how we can
              improve the site for you.
            </p>
          </li>
          <li>
            <p>
              From time to time we test new features and make subtle changes to the way that the
              site is delivered. When we are still testing new features these cookies may be used to
              ensure that you receive a consistent experience whilst on the site whilst ensuring we
              understand which optimisations our users appreciate the most.
            </p>
          </li>
          <li>
            <p>
              As we sell products it’s important for us to understand statistics about how many of
              the visitors to our site actually make a purchase and as such this is the kind of data
              that these cookies will track. This is important to you as it means that we can
              accurately make business predictions that allow us to monitor our advertising and
              product costs to ensure the best possible price.
            </p>
          </li>
          <li>
            <p>
              The Google AdSense service we use to serve advertising uses a DoubleClick cookie to
              serve more relevant ads across the web and limit the number of times that a given ad
              is shown to you.
            </p>
            <p>
              For more information on Google AdSense see the official Google AdSense privacy FAQ.
            </p>
          </li>
          <li>
            <p>
              We use adverts to offset the costs of running this site and provide funding for
              further development. The behavioural advertising cookies used by this site are
              designed to ensure that we provide you with the most relevant adverts where possible
              by anonymously tracking your interests and presenting similar things that may be of
              interest.
            </p>
          </li>
          <li>
            <p>
              We also use social media buttons and/or plugins on this site that allow you to connect
              with your social network in various ways. For these to work the following social media
              sites including Facebook, Instagram & Twitter, will set cookies through our site which
              may be used to enhance your profile on their site or contribute to the data they hold
              for various purposes outlined in their respective privacy policies.
            </p>
          </li>
        </ul>
        <b>More Information</b>
        <p>
          Hopefully that has clarified things for you and as was previously mentioned if there is
          something that you aren’t sure whether you need or not it’s usually safer to leave cookies
          enabled in case it does interact with one of the features you use on our site.
        </p>
        <p>
          However if you are still looking for more information then you can contact us through one
          of our preferred contact methods:
        </p>
        <ul>
          <li>
            <p>Email: administrator@getbroadcasting.com</p>
          </li>
          <li>
            <p>Phone: +353016755050</p>
          </li>
        </ul>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  padding: 2em;
  margin: ${({ headerHeight, viewportWidth }) =>
    viewportWidth >= 996
      ? '97.3594px auto 0'
      : viewportWidth < 575
      ? '60.375px auto 0'
      : `${headerHeight}px auto 0`};

  p {
    margin-bottom: 1em;
  }

  ul li h5 {
    margin-bottom: 0em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  li {
    margin-left: 3em;
  }
`;

export default CookiePolicy;
